import { useIntl } from "react-intl";
import { useTheme } from "styled-components";
import { Legend, Progress, RadialChart } from "./RadialC.styles";
import { Attribute, AttributesTypes } from "./index";
import { ChartWrapper } from "./score.styles";

const MULTIPLIER = 100;

const RadialC = ({ attributes, totalScore }: { attributes: Attribute[]; totalScore: number; }) => {
	const intl = useIntl();
	const theme = useTheme();

	const scores = [AttributesTypes.Procedural, AttributesTypes.Physiological]
		.reduce((acc: { [key: string]: number }, key: string) => {
			const a = attributes.filter(a => a.type.toUpperCase() === key);
			const score = a.reduce((acc, cur) => acc + cur.score, 0) * 2;

			acc[key] = Math.round(score * MULTIPLIER);
			return acc;
		}, {});

	const LINES_COLORS = [
		'#EE7127',
		'#0B5370',
		"#8D2260",
	];

	return (
		<ChartWrapper flex={0.4}>
			<RadialChart.Wrapper>
				<RadialChart.Charts>
					<ProgressBar value={Math.round(totalScore * MULTIPLIER)} color={LINES_COLORS[0]} size={8} />
				</RadialChart.Charts>
				<RadialChart.Charts>
					<ProgressBar value={scores[AttributesTypes.Procedural]} color={LINES_COLORS[1]} size={7} />
					<ProgressBar value={scores[AttributesTypes.Physiological]} color={LINES_COLORS[2]} size={7} />
				</RadialChart.Charts>

				<Legend.Wrapper>
					<LegendItem
						label={intl.formatMessage({ id: "session-detail:overall-score", defaultMessage: "Overall Score" })}
						score={Math.round(totalScore * MULTIPLIER)} color={LINES_COLORS[0]} />
					<LegendItem
						label={intl.formatMessage({ id: "session-detail:procedural", defaultMessage: "Procedural" })}
						score={scores[AttributesTypes.Procedural]} color={LINES_COLORS[1]} />
					<LegendItem
						label={intl.formatMessage({ id: "session-detail:physiological", defaultMessage: "Physiological" })}
						score={scores[AttributesTypes.Physiological]} color={LINES_COLORS[2]} />
				</Legend.Wrapper>
			</RadialChart.Wrapper>
		</ChartWrapper>
	);
};

const LegendItem = ({ label, score, color }: { label: string, score: number, color: string; }) => (
	<Legend.Item>
		<Legend.Section space={0.5}>
			<Legend.Circle color={color}></Legend.Circle>
			<Legend.Name>{label}:</Legend.Name>
		</Legend.Section>
		<Legend.Section space={0}>
			<Legend.Score color={color}>{score}</Legend.Score>
			<Legend.Percentage>/100</Legend.Percentage>
		</Legend.Section>
	</Legend.Item>
);

const ProgressBar = ({ value, color, size }: { value: number; color: string; size: number }) => (
	<Progress.Container size={size}>
		<Progress.Outer value={value} color={color}>
			<Progress.Inner>
				<Progress.Score color={color}>{value}</Progress.Score>
				<Progress.Percentage>/100</Progress.Percentage>
			</Progress.Inner>
		</Progress.Outer>
	</Progress.Container>
);

export default RadialC;
